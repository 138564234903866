<template>
  <div id="Middle">
    <div class="middle_first_box" id="gmap">
      <div class="dataSelect">
        <div class="dataSelect-item">
          <div style="margin-left: 0.25rem; display: flex; align-items: center">
            <label class="labelStyle">场所类型</label>
            <treeselect
              class="selectStyle"
              v-model="place"
              :multiple="false"
              :options="placeList"
              placeholder="请选择"
              :searchable="false"
              @change="selectplace"
            />
          </div>
          <div style="margin-left: 0.25rem; display: flex; align-items: center">
            <label class="labelStyle">所属机构</label>
            <treeselect
              class="selectStyle"
              v-model="office"
              :multiple="false"
              :options="officeList"
              :searchable="false"
              placeholder="请选择"
              :clearable="false"
            />
          </div>
        </div>

        <div
          @click="fullscreenHandle()"
          v-if="!isFullscreen"
          class="fullscreen"
        >
          <img
            class="fullscreenIcon"
            src="../../assets/fullScreen.png"
          />全屏展示
        </div>
        <div v-else @click="fullscreenExit()" class="fullscreen">
          <img class="fullscreenIcon" src="../../assets/unFull.png" />取消全屏
        </div>
      </div>
      <Gmap ref="Cmap" />
    </div>

    <div class="middle_last_box">
      <div class="title_box">
        <img src="../../assets/title_icon.png" alt="" />
        <span>核验人次趋势</span>
      </div>
      <div class="histogram_chart">
        <div class="histogram_chart_box">
          <histogram />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Gmap from "../components/map/map";
import histogram from "../../components/echart/histogram.vue";
import { officeTreeList, dictDataList } from "../../util/api";
import Treeselect from "@riophae/vue-treeselect";
import screenfull from "screenfull";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  data() {
    return {
      loading: true,
      place: this.$store.state.place,
      office: this.$store.state.sysOrgCode,
      officeList: [],
      placeList: [],
      isFullscreen: false,
    };
  },
  components: {
    Gmap,
    histogram,
    Treeselect,
  },
  watch: {
    place: {
      handler(newValue) {
        this.$store.commit("setPlaceValue", newValue); //将值存在vuex 中
      },
      immediate: true,
      deep: true,
    },
    office: {
      handler(newValue) {
        this.$store.commit("setCheckLogs", []);
        this.$store.commit("setSysOrgCodeValue", newValue); //将值存在vuex 中
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.officeTreeList();
    this.fetchDictList();
  },
  mounted() {
    this.init();
    this.cancelLoading();
  },
  beforeDestroy() {
    this.destroy();
  },

  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },

    selectplace(e) {
      this.place = e.target.value;
    },

    async fetchDictList() {
      this.$http.get(dictDataList, { dictCode: "org_place" }).then((data) => {
        if (data.code === 200) {
          this.placeList = data.result.map((item) => {
            return {
              ...item,
              id: item.value,
              label: item.text,
            };
          });
        } else {
          this.$message.error(data.message);
        }
      });
    },

    async officeTreeList() {
      let that = this;
      let roles = localStorage.getItem("prevention_web_user_roles").split(",");
      let isAdmin = false;
      if (roles.includes("admin")) {
        isAdmin = true;
      }
      await this.$http.get(officeTreeList, {}).then(function (res) {
        console.log(res);
        if (res && res.code == 0) {
          if (isAdmin) {
            that.officeList = [{
              label: "全部",
              id: '',
              children: res.result.map((org) => that.mapTree(org)),
            }];
          } else {
            that.officeList = res.result.map((org) => that.mapTree(org));
          }
          if (that.officeList.length) {
            console.log(that.officeList)
            that.office = that.officeList[0].id;
            that.$store.commit("setCheckLogs", []);
            that.$store.commit("setSysOrgCodeValue", that.officeList[0].id); //将值存在vuex 中
          } else {
            that.loginOut();
          }
        } else {
          that.$message.error(res.message);
        }
      });
    },
    mapTree(org) {
      //格式化树型结构数据
      const haveChildren =
        Array.isArray(org.children) && org.children.length > 0;
      if (haveChildren) {
        return {
          label: org.title,
          id: org.orgCode,
          children: haveChildren
            ? org.children.map((i) => this.mapTree(i))
            : null,
        };
      } else {
        return {
          label: org.title,
          id: org.orgCode,
        };
      }
    },
    fullscreenHandle() {
      const element = document.getElementById("gmap");
      if (!screenfull.enabled) return alert("您的浏览器不支持全屏");
      screenfull.request(element);
      console.log("开启全屏");
      this.$refs.Cmap.updateData();
      this.isFullscreen = !this.isFullscreen;
    },
    fullscreenExit() {
      screenfull.exit();
      console.log("退出全屏");
      this.$refs.Cmap.updateData();
      this.isFullscreen = !this.isFullscreen;
    },
    change() {
      this.$refs.Cmap.resetMap();
      this.isFullscreen = screenfull.isFullscreen;
    },
    init() {
      if (screenfull.enabled) {
        screenfull.on("change", this.change);
      }
    },
    destroy() {
      if (screenfull.enabled) {
        screenfull.off("change", this.change);
      }
    },

    loginOut() {
      localStorage.removeItem("pro__Access-Token");
      this.$router.replace("/");
      this.$message.error("暂无机构权限，请分配机构后再试！");
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/firstPage/middleGroup.scss";
</style>