
<template>
  <div class="echarts">
    <span class="chartTitle">月概况</span>
    <div id="proportionMonth"></div>
  </div>
</template>
 
<script>
import Echarts from "echarts";
import { tooltipConfig, echartsLoop } from "../../util/config";

let loopTimer = null;

export default {
  data() {
    return {
      myChart_proportionMonth: {},
    };
  },
  watch: {
    "$store.state.healthCodeRadio": {
      handler: function (newVal) {
        this.healthCodeValue = newVal;

        this.loadEchart();
      },
    },
  },
  created() {
    this.$nextTick(() => {
      this.loadEchart();
      echartsLoop(this.myChart_proportionMonth, 2000, 0);
    });
  },

  destroyed() {
    loopTimer && clearInterval(loopTimer);
  },

  mounted() {
    let _this = this;
    window.addEventListener(
      "resize",
      () => _this.myChart_proportionMonth.resize(),
      false
    );
  },

  methods: {
    loadEchart() {
      let _this = this;
      this.myChart_proportionMonth = Echarts.init(
        document.getElementById("proportionMonth")
      );
      this.myChart_proportionMonth.setOption({
        color: ["#f19a18", "#00c6ff"],

        legend: {
          show:false,
          bottom: "0",
          left: "center",
          data: ["异常", "正常"],
          textStyle: {
            color: "#08b9ec",
          },
        },
        series: [
          {
            type: "pie",
            name: "月概况",
            radius: ["40%", "60%"],
            center: ["50%", "55%"],
            avoidLabelOverlap: false,
            minAngle: 5,
           label: {
              position: "outer",
              alignTo: "labelLine",
              margin: 20,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "12",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
            },
            data: [
              {
                value: this.healthCodeValue
                  ? this.healthCodeValue.month.checkHealthCodeAdd
                  : 0,
                name: "正常",
              },
              {
                value: this.healthCodeValue
                  ? this.healthCodeValue.month.errorHealthCode
                  : 0,
                name: "异常",
              },
            ],
          },
        ],
        tooltip: {
          ...tooltipConfig,
          show: true, // 显示提示框组件
          triggerOn: "mousemove", // 出发条件
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
      });
      window.addEventListener(
        "resize",
        () => _this.myChart_proportionMonth.resize(),
        false
      );
    },
  },
};
</script>
 
<style scoped>
#proportionMonth {
  width: 100%;
  height: 18vh;
}
.chartTitle {
  display: block;
  color: #00c6ff;
  margin-top:0.125rem;
}
.echarts {
  text-align: center;
}
</style>