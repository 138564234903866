
<template>
  <div class="echarts">
    <div id="histogram"></div>
  </div>
</template>
 
<script>
import Echarts from "echarts";
import {tooltipConfig,echartsLoop} from '../../util/config';

export default {
  data() {
    return {
      myChart: {},
      countList: this.$store.state.checkCounts,
    };
  },

  watch: {
    "$store.state.checkCounts": { 
      handler: function (newVal) {
        this.countList = newVal;
        this.updateData();
      },
    },
  },


  created() {
    this.$nextTick(() => {
       this.loadEchart();
       echartsLoop(this.myChart, 2000,0)
    });
   
  },
  mounted() {
    let _this = this;
    window.addEventListener(
      "resize",
      () => _this.myChart.resize(),
      false
    );
  },
  methods: {
    loadEchart() {
      this.myChart = Echarts.init(document.getElementById("histogram"));
      this.myChart.setOption({
        textStyle: {
          fontSize: 15,
          color: "#fff",
        },
        title: {
          //柱状图标题的样式设置
          text: "",
          x: "left",
          textStyle: {
            color: "#fff",
            fontSize: "0.15rem",
          },
          padding: [20, 0, 20, 0],
        },
        xAxis: {
          type: "category",
          data: this.getXList(),
          axisLine: {
            lineStyle: {
              type: "solid",
              color: "#2249b8", //左边线的颜色
              width: "2", //坐标线的宽度
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ["#1f48b4"],
              width: 1,
              type: "solid",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              type: "solid",
              color: "#2249b8", //左边线的颜色
              width: "2", //坐标线的宽度
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#1f48b4"],
              width: 1,
              type: "solid",
            },
          },
        },
        grid: {
          top: "20%",
          left: "4%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        series: [
          {
            data: this.getDataList(),
            type: "bar",
            barWidth: 30,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#14b6f3",
                    fontSize: 16,
                  },
                },
                barBorderRadius: [2, 2, 0, 0], //柱体圆角
                color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    //代表渐变色从正上方开始
                    offset: 0, //offset范围是0~1，用于表示位置，0是指0%处的颜色
                    color: "#01b3ff",
                  }, //柱图渐变色
                  {
                    offset: 1, //指100%处的颜色
                    color: "#0338ff",
                  },
                ]),
              },
            },
          },
        ],

        tooltip:{
           ...tooltipConfig,
          show: true,     // 显示提示框组件
          trigger: "item",     // 触发类型
          triggerOn: "mousemove",  // 出发条件
          formatter: function(item) {
            let content = `${item.name}<br/>核验人次：${item.data}`
            return content;
          }
        }
      });
    },


    updateData() {
      let xDataList = this.getXList();
      let dataList = this.getDataList();
     
      this.myChart.setOption({
        xAxis: {
          data: xDataList,
        },
        series:[
          {
            data:dataList,
          }
        ]
      })
    },

    getXList() {
      if(this.countList && this.countList.length > 0) {
        return  this.countList.map(item => item.countDate);
      }

      return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    },

    getDataList() {
      if(this.countList && this.countList.length > 0) {
        return this.countList.map(item => item.checkAdd);
      }
      return [];
    }

  },

 
};
</script>
 
<style>
#histogram {
  width: 100%;
  height: 100%;
  /* height: 25vh; */
}
.echarts {
  height: 100%;
}
</style>