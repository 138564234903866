
<template>
  <div class="echarts">
    <div ref="map" id="map" style="width: 100%; height: 100%"></div>
  </div>
</template>
 
<script>
import Echarts from "echarts";
import { tooltipConfig, echartsLoop } from "../../../util/config";
import changzhouJson from "../../../assets/json/changzhou";
import liyangJson from "../../../assets/json/liyang";
import jintanJson from "../../../assets/json/jintan";
import tianningJson from "../../../assets/json/tianning";
import wujinJson from "../../../assets/json/wujin";
import xinbeiJson from "../../../assets/json/xinbei";
import zhonglouJson from "../../../assets/json/zhonglou";

export default {
  name: "myMap",

  data() {
    return {
      name: "map",
      buildList: this.$store.state.buildList,

      mapName: "常州市",
      myChart: null,

      jsonMap: {
        常州市: changzhouJson,
        武进区: wujinJson,
        溧阳市: liyangJson,
        金坛区: jintanJson,
        天宁区: tianningJson,
        新北区: xinbeiJson,
        钟楼区: zhonglouJson,
      },
    };
  },

  watch: {
    "$store.state.buildList": {
      handler: function (newVal) {
        this.buildList = newVal;

        this.updateData();
      },
    },
  },

  created() {
    // 注册地图
    for (let index in this.jsonMap) {
      Echarts.registerMap(index, this.jsonMap[index]);
    }
  },

  mounted() {
    this.mapConfigure(this.mapName);
  },

  methods: {
    mapConfigure(area) {
      this.$nextTick(() => {
        echartsLoop(this.myChart, 2000, 0);
      });
      this.myChart = Echarts.init(document.getElementById("map"));
      window.onresize = this.myChart.resize;

      let option = {
        layoutCenter: ["50%", "50%"], //位置
        layoutSize: "80%", //大小
        roam: true,
        scaleLimit: {
          //滚轮缩放的极限控制
          min: 1,
          max: 10000,  // 矢量图可以无限放大
        },
        geo: {
          type: "map",
          map: area,
          zoom: 1.1,
          show: true,
          itemStyle: {
            areaColor: {
              type: "linear",
              colorStops: [
                {
                  offset: 0,
                  color: "#27c9ef", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#084f8c", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
            emphasis: {
              // 区域选中样式
              borderWidth: 2,
              borderColor: "#2486ed",
              areaColor: "#2486ed",
              itemStyle: {
                color: "#ffffff",
              },
            },
          },
          label: {
            show: true, //显示地图区域标签
            color: "#ffffff",
          },
        },
        series: [
          {
            name: area,
            type: "scatter",
            coordinateSystem: "geo",
            mapType: area,
            data: this.getMapData(area),
            symbol: "pin",
            symbolSize: "30",
            label: {},
          },
        ],
        tooltip: this.configTooltip(),
      };

      this.myChart.setOption(option, true);

      var that = this;
      this.myChart.on("click", function (params) {
        if (params.componentType == "geo") {
          if (that.mapName == params.name) {
            that.mapName = "常州市";
          } else {
            that.mapName = params.name;
          }

          that.myChart.dispose();
          that.mapConfigure(that.mapName);
        } else if (params.componentType == "series") {
          // 点击坐标点 跳转楼宇大屏
          that.$store.commit("setBuildingName",params.data.name)
          that.$store.commit("setBuildId", params.data.id);
          that.$router.push({ name: "second", params: { data: params.data } });
        }
      });
    },

    configTooltip() {
      return {
        // 提示框组件
        ...tooltipConfig,
        show: true, // 显示提示框组件
        trigger: "item", // 触发类型
        triggerOn: "mousemove", // 出发条件
        formatter: function (item) {
          let data = item.data;
          let content =
            "名称：" +
            data.name +
            "<br/>" +
            "地址：" +
            data.address +
            "<br/>" +
            "设备数：" +
            data.deviceNum +
            "<br/>" +
            "核验数：" +
            data.checkNum;
          return content;
        },
      };
    },

    updateData() {
      console.log("全屏/关闭");
      let dataList = this.getMapData(this.mapName);

      this.myChart.setOption({
        series: [
          {
            data: dataList,
          },
        ],
      });
    },

    resetMap() {
      this.myChart.resize();
    },

    getMapData(area) {
      let dataList = [];
      if (this.buildList && this.buildList.length > 0) {
        this.buildList.map((item) => {
          // 通过区域筛选（暂时这样...）
          if (item.areaNames && item.areaNames.indexOf(area) > 0) {
            dataList.push({
              ...item,
              value: [item.longitude, item.latitude],
            });
          }
        });
      }
      console.log(dataList);
      return dataList;
    },
  },
};
</script>
 
<style  lang="scss">
#proportion {
  width: 100%;
  height: 20vh;
}
</style>