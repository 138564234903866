
<template>
  <div class="echarts">
    <div id="echart"></div>
  </div>
</template>
 
<script>
import Echarts from "echarts";
import { tooltipConfig, echartsLoop } from "../../../util/config";

const colors = [
  "#ffdf7a",
  "#e9518e",
  "#FFFB17",
  "#00e3ff",
  "#f47920",
  "#1d953f",
  "#ef4136",
  "#8552a1",
];
export default {
  watch: {
    "$store.state.placeCounts": {
      handler: function (newVal) {
        this.placeCounts = newVal;

        this.updateData();
      },
    },
  },

  data() {
    return {
      chart: {},
      placeCounts: this.$store.state.placeCounts,
    };
  },
  created() {
    this.$nextTick(() => {
      this.loadEchart();
      echartsLoop(this.chart, 2000, 0);
    });
  },
  mounted() {
    let _this = this;
    window.addEventListener("resize", () => _this.chart.resize(), false);
  },
  methods: {
    loadEchart() {
      this.chart = Echarts.init(document.getElementById("echart"));
      this.chart.setOption({
        legend: {
          show: false,
        },
        toolbox: {
          show: false,
        },
        series: [
          {
            type: "pie",
            radius: ['50%', '70%'],
            center: ["50%", "50%"],
            avoidLabelOverlap: true,
            roseType: "radius",
            itemStyle: {
              borderRadius: 8,
            },
            label: {
              position: "outer",
              alignTo: "labelLine",
              margin: 20,
            },
            data: this.getDataList(),
          },
        ],

        tooltip: {
          ...tooltipConfig,
          trigger: "item",
          formatter: "{b}<br>设备数:{c}台",
        },
      });
    },

    updateData() {
      let dataList = this.getDataList();

      this.chart.setOption({
        series: [
          {
            data: dataList,
          },
        ],
      });
    },

    getDataList() {
      if (this.placeCounts && this.placeCounts.length > 0) {
        return this.placeCounts.map((item, index) => {
          return {
            name: item.placeName,
            value: item.deviceSum,
            itemStyle: {
              color: colors[index],
            },
          };
        });
      }

      return [];
    },
  },
};
</script>
 
<style>
#echart {
  width: 100%;
  height: 100%;
}
</style>