<template>
  <div id="Right">
    <div class="right_first_box">
      <div class="title_box">
        <img src="../../assets/title_icon.png" alt="" />
        <span>健康码比例</span>
      </div>
      <div class="echartMap">
       
        <div style="width: 50%">
          <proportion />
        </div>
         <div style="width: 50%">
          <proportionMonth />
        </div>
      </div>
    </div>

    <div class="right_Second_box">
      <div class="title_box">
        <img src="../../assets/title_icon.png" alt="" />
        <span>实时监测</span>
      </div>
      <div style="overflow: hidden">
        <animList />
      </div>
    </div>
  </div>
</template>
<script>
import proportion from "../../components/echart/proportion";
import proportionMonth from "../../components/echart/proportionMonth.vue";
import animList from "../../components/echart/AnimList.vue";
export default {
  components: {
    proportion,
    proportionMonth,
    animList,
  },

  data() {
    return {
      loading: true,
    };
  },

  mounted() {
    this.cancelLoading();
  },
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/firstPage/rightGroup.scss";
</style>