<template>
  <div id="Left">
    <div class="left_first_box">
      <div class="title_box">
        <img src="../../assets/title_icon.png" alt="" />
        <span>设备概况</span>
      </div>
      <div class="deviceInfoBox">
        <div class="deviceInfo">
          <div class="deviceTitleBox">
            <div class="line"></div>
            <div class="deviceTitle">在线设备</div>
            <div class="line"></div>
          </div>
          <div class="deviceNumBox">
            <div class="deviceNum">
              <div class="number">
                {{ sysCounts ? sysCounts.deviceOnline : "0" }}
              </div>
              <div class="unit">台</div>
            </div>
          </div>
        </div>

        <div class="deviceInfo">
          <div class="deviceTitleBox">
            <div class="line"></div>
            <div class="deviceTitle">离线设备</div>
            <div class="line"></div>
          </div>
          <div class="deviceNumBox">
            <div class="deviceNum">
              <div class="number errorNumber">
                {{
                  sysCounts
                    ? Number(sysCounts.deviceSum) -
                      Number(sysCounts.deviceOnline)
                    : "0"
                }}
              </div>
              <div class="unit">台</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="left_first_box">
      <div class="title_box">
        <img src="../../assets/title_icon.png" alt="" />
        <span>总核验人次</span>
      </div>

      <div class="healthCode_box">
        <div class="healthCode_box_num">
          <div style="line-height: 0.375rem">健康码数量统计</div>
          <div class="healthCode_box_num_box" style="line-height: 0.375rem">
            <div style="width: 50%">
              总数： {{ sysCounts ? sysCounts.checkHealthCodeSum : "0" }}
              <img src="../../assets/up_green.png" alt="" />
            </div>
            <div style="width: 50%">
              今日： {{ sysCounts ? sysCounts.checkHealthCodeDay : "0" }}
              <img src="../../assets/up_green.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="temperature_box">
        <div class="healthCode_box_num">
          <div style="line-height: 0.375rem">体温测量统计</div>
          <div class="healthCode_box_num_box" style="line-height: 0.375rem">
            <div style="width: 50%">
              总数： {{ sysCounts ? sysCounts.checkTempSum : "0" }}
              <img src="../../assets/up_red.png" alt="" />
            </div>
            <div style="width: 50%">
              今日：{{ sysCounts ? sysCounts.checkTempDay : "0" }}
              <img src="../../assets/up_red.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="left_first_box left_first_place">
      <div class="title_box">
        <img src="../../assets/title_icon.png" alt="" />
        <span>重点场所分布</span>
      </div>

      <div class="place_chart">
        <div class="place_chart_box">
          <bintu />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bintu from "../../components/echart/centerLeft2/centreLeft1Chart.vue";
export default {
  data() {
    return {
      sysCounts: this.$store.state.sysCounts,
    };
  },
  components: {
    bintu,
  },

  watch: {
    "$store.state.sysCounts": {
      handler: function (newVal) {
        this.sysCounts = newVal;
      },
    },
  },

  created() {},

  methods: {},
};
</script>

<style lang="scss">
@import "../../assets/scss/firstPage/leftGroup.scss";
</style>